import React from "react";
import {Redirect} from "react-router-dom";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Dashboard
import Dashboard from "../pages/Manager/Dashboard";
import Calendar from "../pages/Manager/CalendarPage";
import ZonePage from "../pages/Manager/Zone/ZonePage";
import ZoneImagePage from "../pages/Manager/Zone/ZoneImagePage";
import ZoneEditPage from "../pages/Manager/Zone/ZoneEditPage";
import ZoneListPage from "../pages/Manager/Zone/ZoneListPage";
import RegionListPage from "../pages/Manager/Manage/RegionListPage";
import AdminPage from "../pages/Manager/Users/AdminPage";
import CategoryPage from "../pages/Manager/Manage/CategoryPage";
import LanguagePage from "../pages/Manager/Manage/LanguagePage";
import SocietyPage from "../pages/Manager/SocietyPage";
import ClientPage from "../pages/Manager/Users/ClientPage";
import ClientInfoPage from "../pages/Manager/ClientInfoPage";
import UserSocietyPage from "../pages/Manager/Users/UserSocietyPage";
import OperatingDatesPage from "../pages/Manager/OperatingDatesPage";
import TicketListPage from "../pages/Manager/Ticket/TicketListPage";
import TicketAddPage from "../pages/Manager/Ticket/TicketAddPage";
import TagPage from "../pages/Manager/Manage/TagPage";
import SettingsPage from "../pages/Manager/Manage/SettingsPage";
import TicketResidenceTypePage from "../pages/Manager/Manage/TicketResidenceTypePage";
import TicketClientPackageListPage from "../pages/Manager/Ticket/TicketClientPackageListPage";
import SaleSummaryPage from "../pages/Manager/Stats/SaleSummaryPage";
import ServiceTagPage from "../pages/Manager/Manage/ServiceTagPage";
import BlogAddPage from "../pages/Manager/Blog/BlogAddPage";
import BlogEditPage from "../pages/Manager/Blog/BlogEditPage";

import CouponPage from "../pages/Manager/Coupon/CouponPage";
import CouponListPage from "../pages/Manager/Coupon/CouponListPage";
import CouponStatsPage from "../pages/Manager/Coupon/CouponStatsPage";
import ReferralStatsPage from "../pages/Manager/Referral/ReferralStatsPage";


//Manager Society
import ManagerDashboard from "../pages/ManagerSociety/DashboardPage";
import ManagerListSociety from "../pages/ManagerSociety/ListSocietyPage";
import ManagerSocietyPage from "../pages/ManagerSociety/SocietyPage";
import ManagerSocietyStatsPage from "../pages/ManagerSociety/SocietyStatsPage";
import ManagerUserSocietyPage from "../pages/ManagerSociety/ListUserSocietyPage";
import ManagerSocietyZonePage from "../pages/ManagerSociety/SocietyZoneListPage";
import ManagerSocietyClientListPage from "../pages/ManagerSociety/SocietyClientListPage";
import ManagerSocietyUserListPage from "../pages/ManagerSociety/ListUserPage";
import ManagerTicketClientListPage from "../pages/ManagerSociety/TicketClientListPage";
import ManagerSocietyTicketListPage from "../pages/ManagerSociety/SocietyTicketListPage";
import ManagerTicketSharePage from "../pages/ManagerSociety/TicketSharePage";

//Society
import SocClientPage from "../pages/Society/SocClientPage";
import SocZoneListPage from "../pages/Society/Zone/SocZoneListPage";
import SocZoneEditPage from "../pages/Society/Zone/ZoneEditPage";
import SocSocietyPage from "../pages/Society/SocSocietyPage";
import SocTicketListPage from "../pages/Society/SocTicketListPage";
import SocTicketAddPage from "../pages/Society/SocTicketAddPage";
import SocTicketSharePage from "../pages/Society/SocTicketSharePage";
import SocTicketClientListPage from "../pages/Society/SocTicketClientListPage";
import SocControlPage from "../pages/Society/SocControlPage";
import SocCaptureStatsPage from "../pages/Society/SocCaptureStatsPage";
import SocUserSociety from "../pages/Society/Users/UserSocietyPage";

import SocCaptureTotalStatsPage from "../pages/Society/SocCaptureTotalStatsPage";
import SocDashboard from "../pages/Society/SocDashboard";
import TicketSharePage from "../pages/Manager/Ticket/TicketSharePage";
import GroupAreaPage from "../pages/Manager/GroupAreaPage";
import TicketClientListPage from "../pages/Manager/Ticket/TicketClientListPage";
import SocietySummaryPage from "../pages/Manager/SocietySummaryPage";
import SocietySettingsPage from "../pages/Manager/SocietySettingsPage";
import SocOperatingDatesPage from "../pages/Society/Calendar/OperatingDatesPage";
import SocCalendarPage from "../pages/Society/Calendar/CalendarPage";

import TicketShopPackagePage from "../pages/Manager/Ticket/TicketShopPackagePage";

import ShopPackagePage from "../pages/Manager/Packages/ShopPackagePage";
import ShopPackageListPage from "../pages/Manager/Packages/ShopPackageListPage";

import TicketListAvailabilityPage from "../pages/Manager/Ticket/TicketListAvailabilityPage";
import TicketEditPage from "../pages/Manager/Ticket/TicketEditPage";
import SocCaptureAddPage from "../pages/Society/CaptureAddPage";
import BlogListPage from "../pages/Manager/Blog/BlogListPage";

const obj = JSON.parse(localStorage.getItem("authUser"));

let authProtectedRoutes = [
    {path: "/dashboard", component: Dashboard},
    {path: "/stats/calendar", component: Calendar},
    {path: "/settings", component: SettingsPage},
    //profile
    {path: "/profile", component: UserProfile},
    //Admin
    {path: "/admin", component: AdminPage},
    //Tickets
    {path: "/ticket/type", component: TicketResidenceTypePage},
    {path: "/ticket/list", component: TicketListPage},
    {path: "/ticket/add", component: TicketAddPage},
    {path: "/ticket/edit/:id", component: TicketEditPage},
    {path: "/ticket/share/:id", component: TicketSharePage},
    {path: "/ticket/package", component: TicketShopPackagePage},
    {path: "/ticket/packagelist", component: TicketClientPackageListPage},
    {path: "/ticket/listavailability", component: TicketListAvailabilityPage},
    {path: "/package/add", component: ShopPackagePage},
    {path: "/package/edit/:id", component: ShopPackagePage},
    {path: "/package/list", component: ShopPackageListPage},
    {path: "/blog/add", component: BlogAddPage},
    {path: "/blog/list", component: BlogListPage},
    {path: "/blog/edit/:id", component: BlogEditPage},
    {path: "/coupon/add", component: CouponPage},
    {path: "/coupon/list", component: CouponListPage},
    {path: "/coupon/stats", component: CouponStatsPage},
    {path: "/coupon/edit/:id", component: CouponPage},
    {path: "/referral/stats", component: ReferralStatsPage},

    //Zone
    {path: "/zone/add", component: ZonePage},
    {path: "/zone/edit/:id", component: ZoneEditPage},
    {path: "/zone/image/:id", component: ZoneImagePage},
    {path: "/zone/list", component: ZoneListPage},

    {path: "/area/group/:id", component: GroupAreaPage},

    //Region
    {path: "/region/list", component: RegionListPage},
    //Category
    {path: "/category", component: CategoryPage},
    {path: "/servicetag", component: ServiceTagPage},
    //Tag
    {path: "/tag", component: TagPage},
    //Language
    {path: "/language", component: LanguagePage},
    //Society
    {path: "/society", component: SocietyPage},
    {path: "/societySettings/:id", component: SocietySettingsPage},
    {path: "/summary/:id", component: SocietySummaryPage},

    {path: "/client", component: ClientPage},
    {path: "/client_info/:id", component: ClientInfoPage},
    //Client
    {path: "/usersociety", component: UserSocietyPage},
    //rules
    {path: "/rule/operating/dates", component: OperatingDatesPage},

    //stats
    {path: "/stats/ticket", component: TicketClientListPage},
    {path: "/stats/sale", component: SaleSummaryPage},

    //SOCIETY
    {path: "/soc/dashboard", component: SocDashboard},
    {path: "/soc/client", component: SocClientPage},
    {path: "/soc/zone/list", component: SocZoneListPage},
    {path: "/soc/society", component: SocSocietyPage},
    {path: "/soc/ticket/list", component: SocTicketListPage},
    {path: "/soc/ticket/add", component: SocTicketAddPage},
    {path: "/soc/ticket/edit/:id", component: SocTicketAddPage},
    {path: "/soc/ticket/share/:id", component: SocTicketSharePage},
    {path: "/soc/ticket/client/list", component: SocTicketClientListPage},
    {path: "/soc/ticket/control/:type", component: SocTicketClientListPage},
    {path: "/soc/control", component: SocControlPage},

    // this route should be at the end of all other routes
    {
        path: "/",
        exact: true,
        component: () => (localStorage.getItem("authUser") && (obj.user.role === "ROLE_ADMIN" || obj.user.role === "ROLE_SUPERUSER")) ?
            <Redirect to="/dashboard"/> : <Redirect to="/soc/dashboard"/>
    }
];

var sidebarRoutesTmp = [];

if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    switch (obj.user.role) {
        case 'ROLE_SUPERUSER':
        case 'ROLE_ADMIN':
            sidebarRoutesTmp = [
                {path: "/dashboard", icon: null, name: 'Default', sub: null},
                {
                    path: "/#", icon: 'bx bx-wrench', name: 'Admin', sub: [
                        {path: "/category", icon: "bx bxs-grid", name: 'Categorie'},
                        {path: "/tag", icon: "bx bx-tag", name: 'Categorie Tag'},
                        {path: "/servicetag", icon: "bx bx-tag", name: 'Tag Servizi'},
                        {path: "/region/list", icon: "bx bx-map-alt", name: 'Lista regioni'},
                        {path: "/language", icon: "bx bx-world", name: 'Lingue'},
                    ]
                },
                {
                    path: "/#", icon: 'bx bxs-user', name: 'Gestione utenti', sub: [
                        {path: "/admin", icon: "null", name: 'Admin'},
                        {path: "/client", icon: "null", name: 'Client'},
                        {path: "/usersociety", icon: "null", name: 'Gestori'}
                    ]
                },
                {
                    path: "/#", icon: "bx bx-map-alt", name: 'Ticket', sub: [
                        {path: "/ticket/list", icon: "null", name: 'Lista ticket'},
                        {path: "/ticket/add", icon: "null", name: 'Aggiunta ticket'},
                        {path: "/ticket/type", icon: "null", name: 'Tipologia ticket'},
                        {path: "/ticket/listavailability", icon: "null", name: 'Disponibilità ticket'},
                    ]
                },
                {
                    path: "/#", icon: "bx bx-map-pin", name: 'Zona', sub: [
                        {path: "/zone/list", icon: "null", name: 'Lista zone'},
                        {path: "/zone/add", icon: "null", name: 'Aggiunta zone'},
                    ]
                },
                {
                    path: "/#", icon: "bx bx-store-alt", name: 'Ente', sub: [
                        {path: "/society", icon: "null", name: 'Ente'},
                        {path: "/usersociety", icon: "null", name: 'Utenti'}
                    ]
                },
                {
                    path: "/#", icon: "bx bx-bar-chart-square", name: 'Statistiche', sub: [
                        {path: "/stats/ticket", icon: "null", name: 'Ticket acquistati'},
                        {path: "/stats/sale", icon: "null", name: 'Statistiche vendita'},
                    ]
                },
                {
                    path: "/#", icon: "bx bx-cart", name: 'Pacchetti', sub: [
                        {path: "/package/add", icon: "null", name: 'Aggiungi pacchetti'},
                        {path: "/ticket/packagelist", icon: "null", name: 'Pacchetti acquistati'},
                        {path: "/package/list", icon: "null", name: 'Lista pacchetti'},
                    ]
                },
                {
                    path: "/#", icon: "bx bx-calendar", name: 'Calendario', sub: [
                        {path: "/stats/calendar", icon: "null", name: 'Calendario'},
                        {path: "/rule/operating/dates", icon: "null", name: 'Giorni di lavoro'}
                    ]
                },
                {
                    path: "/#", icon: "bx bx-book-bookmark", name: 'Blog', sub: [
                        {path: "/blog/add", icon: "null", name: 'Aggiunta post'},
                        {path: "/blog/list", icon: "null", name: 'Lista post'}
                    ]
                },
                {
                    path: "/#", icon: "bx bx-book-bookmark", name: 'Coupon', sub: [
                        {path: "/coupon/add", icon: "null", name: 'Aggiunta coupon'},
                        {path: "/coupon/list", icon: "null", name: 'Lista coupon'},
                        {path: "/coupon/stats", icon: "null", name: 'Statistiche'}
                    ]
                },
                {path: "/referral/stats", icon: "bx bxs-book-add", name: 'Referral statistiche', sub: null},

            ];
            break;
        case 'ROLE_MANAGER_SOCIETY':
            authProtectedRoutes = [
                {path: "/dashboard", component: ManagerDashboard},
                {path: "/society_list", component: ManagerListSociety},
                {path: "/society_stats/:id", component: ManagerSocietyStatsPage},
                {path: "/society/:id", component: ManagerSocietyPage},
                {path: "/user_society/:id", component: ManagerUserSocietyPage},
                {path: "/society_zone/:id", component: ManagerSocietyZonePage},
                {path: "/society_client/:id", component: ManagerSocietyClientListPage},
                {path: "/user_list", component: ManagerSocietyUserListPage},
                {path: "/stats/ticket", component: ManagerTicketClientListPage},
                {path: "/ticket_list/:id", component: ManagerSocietyTicketListPage},
                {path: "/ticket_share/:id", component: ManagerTicketSharePage},
                {path: "/profile", component: UserProfile},
            ]
            sidebarRoutesTmp = [
                {path: "/dashboard", icon: null, name: 'Home', sub: null},
                {path: "/society_list", icon: "bx bx-store-alt", name: 'Lista enti', sub: null},
                {path: "/user_list", icon: 'bx bxs-user', name: 'Lista utenti', sub: null},
                {
                    path: "/#", icon: "bx bx-bar-chart-square", name: 'Statistiche', sub: [
                        {path: "/stats/ticket", icon: "null", name: 'Ticket acquistati'},
                    ]
                },
            ];
            break;
        case 'ROLE_USER_SOCIETY':
            authProtectedRoutes = [
                {path: "/dashboard", component: SocDashboard},
                {path: "/user/society", component: SocUserSociety},
                {path: "/soc/dashboard", component: SocDashboard},
                {path: "/soc/client", component: SocClientPage},
                {path: "/zone/list", component: SocZoneListPage},
                {path: "/zone/edit/:id", component: SocZoneEditPage},
                {path: "/soc/society", component: SocSocietyPage},
                {path: "/soc/ticket/list", component: SocTicketListPage},
                {path: "/soc/ticket/add", component: SocTicketAddPage},
                {path: "/soc/ticket/edit/:id", component: SocTicketAddPage},
                {path: "/soc/ticket/share/:id", component: SocTicketSharePage},
                {path: "/soc/ticket/client/list", component: SocTicketClientListPage},
                {path: "/soc/ticket/control/:type", component: SocTicketClientListPage},
                {path: "/soc/control", component: SocControlPage},
                {path: "/soc/add/capture", component: SocCaptureAddPage},
                {path: "/soc/stats/capture", component: SocCaptureStatsPage},
                {path: "/soc/stats/capturetotal", component: SocCaptureTotalStatsPage},
                {path: "/profile", component: UserProfile},
                {path: "/stats/calendar", component: SocCalendarPage},
                {path: "/operating/dates", component: SocOperatingDatesPage},

            ];
            sidebarRoutesTmp = [
                {path: "/soc/dashboard", icon: null, name: 'Default', sub: null},
                {path: "/soc/client", icon: 'bx bxs-user', name: 'Client'},
                {path: "/user/society", icon: 'bx bxs-user', name: 'Utenti ente'},
                {path: "/soc/society", icon: "bx bx-store-alt", name: 'Society'},
                {path: "/zone/list", icon: "bx bx-map-pin", name: 'ZoneList'},
                {
                    path: "/#", icon: "bx bx-map-alt", name: 'Ticket', sub: [
                        {path: "/soc/ticket/list", icon: "null", name: 'ListTicket'},
                        {path: "/soc/ticket/add", icon: "null", name: 'AddTicket'},
                    ]
                },
                {
                    path: "/#", icon: "bx bx-calendar", name: 'Calendario', sub: [
                        {path: "/stats/calendar", icon: "null", name: 'Calendario'},
                        {path: "/operating/dates", icon: "null", name: 'Giorni di lavoro'}
                    ]
                },
                {
                    path: "/#",
                    icon: "bx bx-bar-chart-square",
                    name: 'Statistiche',
                    sub: [{path: "/soc/ticket/client/list", icon: "null", name: 'ClientTicket'}]
                }
            ];

            break;
        default:
            authProtectedRoutes = [
                {path: "/dashboard", component: SocControlPage},
                {path: "/soc/ticket/list", component: SocTicketListPage},
                {path: "/soc/ticket/add", component: SocTicketAddPage},
                {path: "/soc/ticket/client/list", component: SocTicketClientListPage},
                {path: "/profile", component: UserProfile},
            ];
            sidebarRoutesTmp = [
                {
                    path: "/#", icon: "bx bx-map-alt", name: 'Ticket', sub: [
                        {path: "/soc/ticket/list", icon: "null", name: 'List Ticket'},
                        {path: "/soc/ticket/add", icon: "null", name: 'Add Ticket'},
                        {path: "/soc/ticket/client/list", icon: "null", name: 'Client Ticket'},
                    ]
                },
            ];
            break;
    }
}

const sidebarRoutes = sidebarRoutesTmp;

const publicRoutes = [
    {path: "/logout", component: Logout},
    {path: "/login", component: Login},

];

export {authProtectedRoutes, publicRoutes, sidebarRoutes};
