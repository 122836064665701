import {apiCall, UploadFile} from '../../../helpers/backend_helper';

export const infoSociety = async (id) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/society/info', data: {id: id}});
    } catch (error) {
        return false;
    }
}

export const getSociety = async (page) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/society/list', data: {page: page}});
        if (typeof response.list !== 'undefined') {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const setSociety = async (society) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/society/add', data: society});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const editSociety = async (society) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));

        let response = await apiCall({method: 'POST', url: 'admin/society/edit', data: society});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const disableSociety = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({method: 'POST', url: 'admin/society/disable', data: data});
    } catch (error) {
        return false;
    }
}

export const uploadImageTicket = async (data) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await UploadFile({method: 'POST', url: 'admin/society/ticket/upload', data: data});
    } catch (error) {
        return false;
    }
}

export const editSocietyEmail = async (society) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/society/edit/email', data: society});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}
export const editSocietyTicketSettings = async (society) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({method: 'POST', url: 'admin/society/edit/ticket', data: society});
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}


export const addReferral = async (id_society, code, percentage) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({
            method: 'POST',
            url: 'admin/society/referral/add',
            data: {id_society: id_society, code: code, referral_percentage: percentage}
        });
    } catch (error) {
        return false;
    }
}


export const editReferral = async (id_referral, percentage) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({
            method: 'POST',
            url: 'admin/society/referral/add',
            data: {id_referral: id_referral, referral_percentage: percentage}
        });
    } catch (error) {
        return false;
    }
}

export const deleteReferral = async (id_referral) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        return await apiCall({
            method: 'POST',
            url: 'admin/society/referral/delete',
            data: {id: id_referral}
        });
    } catch (error) {
        return false;
    }
}

export const listReferralSociety = async (id_society) => {
    try {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        let response = await apiCall({
            method: 'POST',
            url: 'admin/society/referral/list/society',
            data: {id: id_society}
        });
        if (response) {
            return response;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const apiError = (error) => {
    return {
        type: 'API_ERROR',
        payload: error
    }
}