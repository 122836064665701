import React, {Component} from "react";
import {
    Card,
    CardBody,
    Container,
    Button,
    ButtonGroup,
    CardTitle,
    CardText,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ListGroup,
    ListGroupItem,
    Badge, Input,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {AvForm, AvField} from 'availity-reactstrap-validation';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    addTicket,
    getInfoSettings,
    getListAreas,
    getListServiceTag,
    ticketResidenceList
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import {DataGrid} from "@material-ui/data-grid";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import ReactQuill from "react-quill";
import InputMask from "react-input-mask";

var moment = require('moment');

class TicketAddPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{headerName: "Nome area", field: "name", width: 180},
                {
                    headerName: "Categoria",
                    field: "category",
                    width: 140,
                    renderCell: (params) => params.formattedValue.name
                },
                {headerName: "Regione", field: "region", width: 160},
                {headerName: "Province", field: "province", width: 160},
                {headerName: "Indirizzo", field: "address", width: 160},
                {headerName: "N°", field: "number", width: 120},
                {headerName: "Città", field: "city", width: 160},
                {headerName: "CAP", field: "cap", width: 140},
                {headerName: "Telefono", field: "phone", width: 140},
                {
                    headerName: "Zone",
                    width: 150,
                    field: 'id',
                    renderCell: (params) => (Object.keys(params.getValue(params.id, "micro_areas")).length > 0 ?
                        <Button type="button"
                                color="primary">{Object.keys(params.getValue(params.id, "micro_areas")).length}</Button> : " ")
                },
                {
                    headerName: "Gruppo zone",
                    width: 150,
                    field: 'id_',
                    renderCell: (params) => (Object.keys(params.getValue(params.id, "micro_areas_group")).length > 0 ?
                        <Button type="button"
                                color="primary">{Object.keys(params.getValue(params.id, "micro_areas_group")).length}</Button> : " ")
                }],
            columnsMicroArea: [{headerName: "Nome zona", field: "name", width: 240}],
            columnsMicroAreaGroup: [{headerName: "Nome gruppo", field: "name", width: 240}, {
                headerName: "Zone", field: "micro_areas", width: 500,
                renderCell: (params) => params.getValue(params.id, "micro_areas").map(item => {
                    return item.name + " - ";
                })
            }],
            rows: [],
            rowsMicroArea: [],
            rowsMicroAreaGroup: [],
            paginator: {loading: true, countRows: 0, limit: 50},
            paginatorSociety: {loading: true, countRows: 0, limit: 50, page: 1},
            form_edit: {
                service_fee: null,
                maximum_ticket_purchasable: 20,
                duration: "00:30",
                date_start_validity: (moment.parseZone(new Date()).set("hour", 0).set("minute", 0)).local().format("DD/MM/YYYY HH:mm"),
                date_end_validity: (moment.parseZone(new Date()).set("hour", 23).set("minute", 59)).local().format("DD/MM/YYYY HH:mm"),
            },
            arr_service_fee: [],
            description: null,
            listServiceTags: [],
            service_tags: [],
            date_start: moment().set("hour", 0).set("minute", 0).toDate(),
            date_end: moment().set("hour", 23).set("minute", 59).toDate(),
            date_current: moment().set("hour", 0).set("minute", 0).toDate(),
            area: null,
            micro_area: null,
            micro_area_group: null,
            ticket: null,
            residenceTypes: [],
            showTab: "area",
            modal: false,
            modalExtraData: false,
            modalCommission: false,
            service_fee: null,
            arr_extra_data: [],
            extraDataSelect: null,
            fieldSelect: null,
            typeTicket: "DAILY"
        };
        this.quill = null;
    }

    async componentDidMount() {
        let form_edit = this.state.form_edit;
        let residenceTypes = await ticketResidenceList();
        if (typeof residenceTypes.error === "undefined")
            this.setState({residenceTypes: residenceTypes.list});
        let settings = await getInfoSettings();
        if (typeof settings.error === "undefined") {
            form_edit.service_fee = settings.settings.service_fee;
            form_edit.maximum_ticket_purchasable = settings.settings.maximum_ticket_purchasable;
            this.setState({service_fee: settings.settings.service_fee, form_edit: form_edit});
        }
        this.showArea("area");

        getListServiceTag().then(result => {
            if (!result) {
                this.setState({listServiceTags: []});
            } else {
                this.setState({listServiceTags: result.list});
            }
        });
    }

    async handleValidSubmit(event, values) {
        let form = this.state.form_edit;
        if (this.state.area === null) {
            global.notify("Devi selezionare l'area a cui associare il ticket", "danger");
            return;
        }
        values.id_area = this.state.area.id;
        values.arr_extra_data = JSON.stringify(this.state.arr_extra_data);
        if (this.state.micro_area !== null)
            values.id_micro_area = this.state.micro_area.id;

        if (this.state.micro_area_group !== null)
            values.id_micro_area_group = this.state.micro_area_group.id;
        if (this.state.description !== null && this.state.description.trim() !== "" && this.state.description !== "<p><br></p>")
            values.description = this.state.description;

        values.service_tags = JSON.stringify(this.state.service_tags);
        if (values.last_price === null)
            delete values.last_price;

        if (this.state.typeTicket === "HOURLY") {
            let val = form.duration.split(":");
            if (!(val[0] === "00" && val[1] === "00"))
                values.duration = parseInt(val[0]) * 60 * 60 + parseInt(val[1]) * 60;
        }

        let formResult = await addTicket(values);
        if (typeof formResult.ticket !== 'undefined') {
            global.notify("Ticket aggiunto con successo", "success");
            this.form && this.form.reset();
        } else if (typeof formResult.error !== 'undefined') {
            global.notify("Errore con l'aggiunta: " + formResult.error, "danger");

            this.setState({error: formResult.error});
        } else {
            this.setState({error: 'Errore generico'});
        }
    }

    setDate = (date, type) => {
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start_validity = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end_validity = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    showArea = (state) => {
        if (state !== "area" && this.state.area === null) {
            global.notify("Devi selezionare almeno un'area", "warning");
            return;
        }
        if (state === "micro_area_group" && (typeof this.state.area.micro_areas_group === "undefined" || Object.keys(this.state.area.micro_areas_group).length === 0)) {
            global.notify("Non ci sono gruppi presenti per quest'area", "warning");
            return;
        }
        if (state === "micro_area" && (typeof this.state.area.micro_areas === "undefined" || Object.keys(this.state.area.micro_areas).length === 0)) {
            global.notify("Non ci sono zone presenti per quest'area", "warning");
            return;
        }

        this.setState({showTab: state});
        if (state === "area") {
            if (Object.keys(this.state.rows).length === 0)
                this.loadListAreas(0);
        }
    }

    loadListAreas = (page) => {
        let paginator = this.state.paginator;
        paginator.loading = true;
        this.setState({paginator: paginator});

        getListAreas(page + 1).then(result => {
            paginator.loading = false;
            if (!result) {
                paginator.countRows = 0;
                this.setState({rows: [], paginator: paginator});
            } else {
                paginator.countRows = result.paginator.count_all_entities;
                this.setState({rows: result.list, rowsArea: result.list, paginator: paginator});
            }
        });
    }

    handlePageChange = (params) => {
        this.loadListAreas(params.page)
    };

    handleRowSelected = (rows) => {
        let row = [...rows.entries()][0][1];
        switch (this.state.showTab) {
            case "area":
                this.setState({
                    area: row,
                    rowsMicroArea: typeof row.micro_areas !== "undefined" ? row.micro_areas : [],
                    rowsMicroAreaGroup: typeof row.micro_areas_group !== "undefined" ? row.micro_areas_group : []
                }, () => this.showArea("micro_area"));
                break;
            case "micro_area_group":
                if (this.state.micro_area !== null)
                    global.notify("Non puoi impostare una zona specifica con il gruppo di zone", "warning");
                this.setState({micro_area_group: row, micro_area: null});
                break;
            case "micro_area":
                if (this.state.micro_area_group !== null)
                    global.notify("Non puoi impostare una zona specifica con il gruppo di zone", "warning");
                this.setState({micro_area: row, micro_area_group: null});
                break;
        }
    }

    renderExtraData = () => {
        let list;
        if (Object.keys(this.state.arr_extra_data).length === 0)
            list = <ListGroupItem>Non ci sono campi aggiuntivi impostati</ListGroupItem>
        else {
            list = this.state.arr_extra_data.map((item, key) => {
                let type = "";
                switch (item.type) {
                    case "STRING":
                        type = "Testo";
                        break;
                    case "DATE":
                        type = "Data";
                        break;
                    case "SELECT":
                        type = "Scelta";
                        break;
                    case "CHECKBOX":
                        type = "Flag";
                        break;
                }
                return <ListGroupItem style={{fontSize: 15}}>
                    <Badge color="light" pill>Nome: <b>{item.name}</b></Badge>
                    <Badge color="light" pill>Tipologia: <b>{type}</b></Badge>
                    <div style={{float: "right"}}>
                        <Button color={"danger"} size="sm" onClick={() => {
                            let arr = this.state.arr_extra_data;
                            delete arr[key];
                            this.setState({arr_extra_data: arr});
                        }}>Elimina</Button>
                    </div>
                </ListGroupItem>
            });
        }

        return <div className={"row"}>
            <div className={"col-md-12"}>
                <hr/>
                <h5>Campi aggiuntivi</h5>
                <div style={{marginTop: "auto"}}>
                    <Button color="info" size={"sm"} onClick={() => this.setState({modalExtraData: true})}>Aggiungi
                        campi</Button>
                </div>
                <br/>
                <ListGroup>{list}</ListGroup>
            </div>
        </div>
    }

    deleteTag = async (id) => {
        let tags = this.state.service_tags;
        const index = tags.indexOf(id);
        if (index > -1) {
            tags.splice(index, 1);
            this.setState({service_tags: tags});
        }
    }

    setTag = async (id) => {
        let tags = this.state.service_tags;
        tags.push(id);
        this.setState({service_tags: tags});
    }

    listServiceTags = () => {
        let list;
        if (Object.keys(this.state.listServiceTags).length === 0)
            list = <ListGroupItem>Non ci sono Tag da impostare</ListGroupItem>
        else {
            list = this.state.listServiceTags.map((item, key) => {
                return <Chip className={"mb-2 mr-1"}
                             label={<strong>{item.name}</strong>}
                             onClick={() => this.state.service_tags.includes(item.id) ? this.deleteTag(item.id) : this.setTag(item.id)}
                             icon={this.state.service_tags.includes(item.id) ? <DoneIcon/> : null}
                             variant={this.state.service_tags.includes(item.id) ? "outlined" : null}
                />
            });
        }
        return <div>{list}</div>
    }

    handleSubmitExtraData(event, values) {
        let arr = this.state.arr_extra_data;
        let arr_values = [];
        if (values.type === "SELECT") {
            if (this.state.extraDataSelect !== null && Object.keys(this.state.extraDataSelect).length > 0) {
                arr_values = this.state.extraDataSelect;
            } else {
                global.notify("Errore: devi impostare dei campi per la scelta", "danger");
                return;
            }
        }
        arr.push({
            name: values.name,
            type: values.type,
            is_required: values.is_required,
            link: values.link,
            values: arr_values
        });

        this.setState({arr_extra_data: arr, modalExtraData: false, extraDataSelect: null})
    }

    renderModalExtraData = () => {
        let extraSelect = "Non ci sono campi impostati";
        let extraDataSelect = this.state.extraDataSelect;
        if (extraDataSelect !== null && Object.keys(extraDataSelect).length > 0) {
            extraSelect = [];
            for (let i in extraDataSelect)
                extraSelect.push(<p><b>{extraDataSelect[i]}</b> <Button color="danger" size={"sm"}
                                                                        onClick={() => {
                                                                            let extraDataSelect = this.state.extraDataSelect;
                                                                            delete extraDataSelect[i];
                                                                            this.setState({extraDataSelect: extraDataSelect});
                                                                        }}>Elimina</Button></p>);
        }

        return <Modal isOpen={this.state.modalExtraData} backdrop="static" keyboard={false}>
            <AvForm onValidSubmit={(e, v,) => {
                this.handleSubmitExtraData(e, v)
            }}>
                <ModalHeader>Aggiungi campi extra per il ticket</ModalHeader>
                <ModalBody>
                    <div className="form-horizontal">
                        <div className={"row"}>
                            <div className="form-group col-md-12">
                                <AvField name="name" label="Nome del campo"
                                         className="form-control" placeholder="Inserisci nome del campo"
                                         type="text" required/>
                            </div>
                            <div className="form-group col-md-12">
                                <AvField name="type" label="Tipologia del campo" onChange={(e) => {
                                    if (e.target.value === "SELECT")
                                        this.setState({extraDataSelect: []});
                                    else
                                        this.setState({extraDataSelect: null});
                                }}
                                         className="form-control" placeholder="Seleziona tipologia del campo"
                                         type="select" required>
                                    <option value={"STRING"}>Testo</option>
                                    <option value={"DATE"}>Data</option>
                                    <option value={"SELECT"}>Scelta</option>
                                    <option value={"CHECKBOX"}>Flag</option>
                                </AvField>
                            </div>
                            {this.state.extraDataSelect !== null ?
                                <div className={"col-md-12"} style={{backgroundColor: "#E0E0E0"}}>
                                    <div className={"row pt-2"}>
                                        <div className="input-group col-md-12 mb-2">
                                            <input name="field_select" label="Valore campo" className="form-control"
                                                   placeholder="Valore campo" value={this.state.fieldSelect}
                                                   onChange={(e) => this.setState({fieldSelect: e.target.value})}
                                                   type="text"/>
                                            <Button color="success"
                                                    onClick={() => {
                                                        let extraDataSelect = this.state.extraDataSelect;
                                                        extraDataSelect.push(this.state.fieldSelect);
                                                        this.setState({
                                                            extraDataSelect: extraDataSelect,
                                                            fieldSelect: ""
                                                        });
                                                    }}>Aggiungi</Button>
                                        </div>
                                        <div className="col-md-12">
                                            {extraSelect}
                                        </div>
                                    </div>
                                </div> : <></>}
                            <div className="form-group col-md-12">
                                <AvField name="link" label="Link" className="form-control" placeholder="Inserisci link"
                                         type="text"/>
                            </div>
                            <div className="form-group col-md-12">
                                <AvField type="checkbox" className="form-check-input"
                                         id="is_required" name="is_required" checked={false}
                                         label="Il campo è obbligatorio"/>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="success">Aggiungi</Button>
                    <Button color="secondary" onClick={() => this.setState({modalExtraData: false})}>Chiudi</Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    }


    onChangeCommission = (index, value) => {
        let arr = this.state.arr_service_fee;
        for (let i = index; i < Object.keys(arr).length; i++)
            arr[i] = value;
        this.setState({arr_service_fee: arr})

    }

    generateCommission = () => {
        let arr_service_fee = [];
        if (Object.keys(this.state.arr_service_fee).length === 0) {
            let arr_service_fee = [];
            arr_service_fee[0] = this.state.form_edit.service_fee;
            for (let i = 1; i < this.state.form_edit.maximum_ticket_purchasable; i++) {
                arr_service_fee[i] = arr_service_fee[0];
            }
            this.setState({arr_service_fee: arr_service_fee});
        }
        return arr_service_fee;
    }

    renderModalCommission = () => {
        if (!this.state.modalCommission)
            return;
        let arr_service_fee = this.state.arr_service_fee;
        let list = [];
        for (let i in arr_service_fee) {
            let index = parseInt(i) + 1;
            list.push(<div className={"row mb-2"}>
                <div className={"col-md-3"}><label>Quantità x{index}</label></div>
                <div className={"col-md-4"}>
                    <Input type="number" value={arr_service_fee[i]} disabled={i === 0} onChange={(e) => {
                        this.onChangeCommission(i, e.target.value);
                    }} placeholder={"Commissione"}/>
                </div>
            </div>);
        }

        return <Modal isOpen={this.state.modalCommission} backdrop="static" keyboard={false}>
            <ModalHeader>Imposta commissioni del ticket</ModalHeader>
            <ModalBody>
                {list}
            </ModalBody>
            <ModalFooter>
                <Button type="submit" color="success">Imposta</Button>
                <Button color="secondary" onClick={() => this.setState({modalCommission: false})}>Chiudi</Button>
            </ModalFooter>
        </Modal>
    }

    render() {
        const form_edit = this.state.form_edit;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem={"Aggiunta ticket"}/>
                        <Card>
                            <CardBody>
                                <div className="text-center mb-4">
                                    <ButtonGroup>
                                        <Button
                                            onClick={() => this.showArea("area")}
                                            color={(this.state.showTab === "area") ? "light" : "primary"}>Area</Button>
                                        <Button
                                            onClick={() => this.showArea("micro_area_group")}
                                            color={(this.state.showTab === "micro_area_group") ? "light" : "primary"}>Gruppo
                                            zone</Button>
                                        <Button
                                            onClick={() => this.showArea("micro_area")}
                                            color={(this.state.showTab === "micro_area") ? "light" : "primary"}>Zona</Button>
                                    </ButtonGroup>
                                </div>
                                <div style={{height: '40vh', width: '100%'}} hidden={(this.state.showTab !== "area")}>
                                    <DataGrid
                                        rows={this.state.rows}
                                        columns={this.state.columns}
                                        pagination
                                        pageSize={this.state.paginator.limit}
                                        rowsPerPageOptions={[this.state.paginator.limit]}
                                        rowCount={this.state.paginator.countRows}
                                        paginationMode="server"
                                        onPageChange={this.handlePageChange}
                                        loading={this.state.paginator.loading}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div style={{height: '40vh', width: '100%'}}
                                     hidden={(this.state.showTab !== "micro_area_group")}>
                                    <DataGrid
                                        rows={this.state.rowsMicroAreaGroup}
                                        columns={this.state.columnsMicroAreaGroup}
                                        pageSize={Object.keys(this.state.rowsMicroAreaGroup).length}
                                        rowsPerPageOptions={[Object.keys(this.state.rowsMicroAreaGroup).length]}
                                        rowCount={Object.keys(this.state.rowsMicroAreaGroup).length}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div style={{height: '40vh', width: '100%'}}
                                     hidden={(this.state.showTab !== "micro_area")}>
                                    <DataGrid
                                        rows={this.state.rowsMicroArea}
                                        columns={this.state.columnsMicroArea}
                                        pageSize={Object.keys(this.state.rowsMicroArea).length}
                                        rowsPerPageOptions={[Object.keys(this.state.rowsMicroArea).length]}
                                        rowCount={Object.keys(this.state.rowsMicroArea).length}
                                        onRowSelected={(x) => this.handleRowSelected(x.api.current.getSelectedRows())}
                                    />
                                </div>
                                <div className={"row"}>
                                    {this.state.area !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Area</CardTitle>
                                                <CardText>Nome area: {this.state.area.name}<br/> {this.state.area.address},
                                                    n°{this.state.area.number} {this.state.area.city} {this.state.area.region}
                                                </CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.micro_area_group !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Gruppo zone</CardTitle>
                                                <CardText>Nome
                                                    gruppo: {this.state.micro_area_group.name}<br/>Zone: {this.state.micro_area_group.micro_areas.map(item => {
                                                        return item.name + " - ";
                                                    })}</CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                    {this.state.micro_area !== null ? <div className={"col-md-6"}>
                                            <Card body>
                                                <CardTitle tag="h5">Zona</CardTitle>
                                                <CardText>Nome zona: {this.state.micro_area.name}</CardText>
                                            </Card>
                                        </div>
                                        : ""}
                                </div>
                                <br/>


                                <AvForm className="form-horizontal" onValidSubmit={(e, v,) => {
                                    this.handleValidSubmit(e, v)
                                }}>
                                    <div className={"row"}>
                                        <div className="form-group col-md-8">
                                            <AvField name="name" label="Nome del ticket"
                                                     className="form-control" placeholder="Inserisci nome ticket"
                                                     type="text" required/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="type" label="Tipologia ticket"
                                                     className="form-control" placeholder="Inserisci tipologia"
                                                     type="select" required value={"DAILY"}
                                                     onChange={(value) => (this.setState({typeTicket: value.target.value}))}
                                            >
                                                <option value={"HOURLY"}>Orario</option>
                                                <option disabled>Tipologie a giorni</option>
                                                <option value={"DAILY"} selected={true}>Giornaliero</option>
                                                <option value={"TWODAYS"}>Bigiornaliero</option>
                                                <option value={"THREEDAYS"}>Tre giorni</option>
                                                <option value={"FOURDAYS"}>Quattro giorni</option>
                                                <option value={"WEEKDAYS"}>Cinque giorni</option>
                                                <option value={"WEEKLY"}>Settimanale</option>
                                                <option value={"TWOWEEKS"}>Bisettimanale</option>
                                                <option value={"MONTHLY"}>Mensile</option>
                                                <option value={"TRIMESTER"}>Trimestrale</option>
                                                <option value={"SEASONAL"}>Stagionale</option>
                                                <option value={"SEMIANNUAL"}>Semestrale</option>
                                                <option value={"YEARLY"}>Annuale</option>
                                                <option value={"BIANNUAL"}>Biennale</option>
                                                <option value={"TRIENNIAL"}>Triennale</option>
                                            </AvField>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="price" label="Prezzo finale"
                                                     className="form-control" placeholder="Inserisci prezzo finale"
                                                     type="text" required/>
                                            <small>il prezzo che verrà addebitato sulla carta</small>
                                        </div>

                                        <div className="form-group col-md-4">
                                            <AvField name="last_price" label="Prezzo pieno"
                                                     className="form-control" placeholder="Inserisci prezzo pieno"
                                                     type="text"/>
                                            <small>Serve per indicare lo sconto, è il prezzo prima dello sconto (non
                                                obbligatorio)</small>
                                        </div>

                                        <div className="col-md-4">
                                            <label>Commissione di servizio</label>
                                            <div style={{display: "flex", flexDirection: "row"}}>
                                                <div style={{flex: 1}}>
                                                    <AvField name="service_fee"
                                                             value={this.state.service_fee}
                                                             className="form-control" placeholder="Commissione"
                                                             type="text"/>
                                                </div>
                                          {/*      <div>
                                                    <button className="btn btn-outline-warning" type="button"
                                                            onClick={() => {
                                                                this.generateCommission();
                                                                this.setState({modalCommission: true})
                                                            }}>Opzioni
                                                    </button>
                                                </div>*/}
                                            </div>
                                        </div>

                                        <div className="form-group col-md-4">
                                            <AvField name="date_start_validity" hidden
                                                     label="Data inizio"
                                                     value={this.state.form_edit.date_start_validity}
                                                     className="form-control"
                                                     placeholder="Inserisci data" type="text"
                                                     required/>
                                            <DatePicker selected={this.state.date_start}
                                                        startDate={this.state.date_current} className="form-control"
                                                        onChange={(date) => this.setDate(date, "start")}
                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="date_end_validity" hidden label="Data fine"
                                                     value={this.state.form_edit.date_end_validity}
                                                     className="form-control"
                                                     placeholder="Inserisci data" type="text"
                                                     required/>

                                            <DatePicker selected={this.state.date_end}
                                                        minDate={this.state.date_start} className="form-control"
                                                        onChange={(date) => this.setDate(date, "end")}
                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                            />
                                        </div>
                                        {this.state.typeTicket === "HOURLY" ?
                                            <div className="form-group col-md-4">
                                                <label htmlFor={"duration"}>Durata del ticket</label>
                                                <InputMask name={"duration"} className="form-control"
                                                           mask={[/[0-9]/, /[0-3]/, ':', /[0-5]/, /[0-9]/]}
                                                           value={form_edit.duration}
                                                           onChange={(e) => {
                                                               let value = e.target.value;
                                                               value = value.replace("_", "0")
                                                               let hours = value.split(":");
                                                               if (parseInt(hours[0]) >= 24)
                                                                   value = "23:" + hours[1];
                                                               form_edit.duration = value;
                                                               this.setState({form_edit: form_edit});
                                                           }}
                                                           placeholder={"Chiusura"}/>
                                            </div> : <></>}

                                        <div className="form-group col-md-4">
                                            <AvField name="id_residence_type" label="Categoria del ticket"
                                                     className="form-control" placeholder="Categoria del ticket"
                                                     type="select" required>
                                                {this.state.residenceTypes.map((data, i) => {
                                                    return <option value={data.id}>{data.name}</option>
                                                })}
                                            </AvField>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className="form-group col-md-4">
                                            <AvField name="maximum_ticket_purchasable"
                                                     value={form_edit.maximum_ticket_purchasable}
                                                     label="Numero massimo di ticket aquistabili"
                                                     className="form-control" onChange={(e) => {
                                                form_edit.maximum_ticket_purchasable = e.target.value;
                                                this.setState({form_edit: form_edit});
                                            }}
                                                     placeholder="Numero massimo di ticket aquistabili"
                                                     type="number"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="maximum_use"
                                                     label="Numero massimo di utilizzi del ticket"
                                                     className="form-control" placeholder="Inserisci numero"
                                                     type="text"/>
                                            <small>(per il segnacatture)</small>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="availability" label="Quantità dei ticket disponibili"
                                                     className="form-control"
                                                     placeholder="Inserisci la disponibilità"
                                                     type="text"/>
                                            <small>(se il campo è vuoto, la disponibilità sarà illimitata)</small>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <hr/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField type="checkbox" className="form-check-input"
                                                     id="is_date_use_required" name="is_date_use_required"
                                                     label="Date d'utilizzo obbligatorie?"/>
                                            <small>(obbligatorio se viene il ticket è associato alle zone a numero
                                                chiuso)</small>

                                        </div>
                                        {/*<div className="form-group col-md-4">*/}
                                        {/*    <AvField type="checkbox" className="form-check-input"*/}
                                        {/*             id="send_regulation" name="send_regulation"*/}
                                        {/*             label="Invio del regolamento via email?"/>*/}
                                        {/*    <small>(verrà inviato il regolamento via email all'utente dopo*/}
                                        {/*        l'acquisto*/}
                                        {/*        del ticket)</small>*/}
                                        {/*</div>*/}
                                        <div className="form-group col-md-4">
                                            <AvField type="checkbox" className="form-check-input"
                                                     id="is_hidden" name="is_hidden"
                                                     label="Ticket nascosto in APP?"/>
                                            <small>(il ticket verrà nascosto in applicazione/sito web e si può
                                                vedere
                                                solo nel gestionale)</small>
                                        </div>
                                        {this.state.area !== null && this.state.area.category.type === "TYPE_FISHING" ?
                                            <div className="form-group col-md-4">
                                                <AvField type="checkbox" className="form-check-input"
                                                         id="is_disabled_score_capture"
                                                         name="is_disabled_score_capture"
                                                         label="Ticket disabilitato per il segnacatture?"/>
                                                <small>(il ticket verrà disabilitato per il segnacatture)</small>
                                            </div> : <></>}
                                        <div className="form-group col-md-4">
                                            <AvField name="minimum_age" label="Età minima"
                                                     className="form-control" placeholder="Età minima"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField name="languages" label="Lingue disponibili"
                                                     className="form-control" placeholder="Lingue disponibili"
                                                     type="text"/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <AvField type="checkbox" className="form-check-input"
                                                     id="is_free_cancellation" name="is_free_cancellation"
                                                     label="Cancellazione gratuita entro 24 ore"/>
                                        </div>
                                    </div>
                                    <hr/>
                                    <p><b>Descrizione ticket</b></p>
                                    <ReactQuill theme="snow"
                                                value={this.state.description}
                                                ref={(ref) => this.quill = ref}
                                                modules={{
                                                    toolbar: {
                                                        container: [
                                                            [
                                                                {header: [1, 2, 3, 4, 5, 6, false]},
                                                            ],
                                                            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'line'],
                                                            [
                                                                {list: 'ordered'},
                                                                {list: 'bullet'},
                                                                {align: []},
                                                            ],
                                                            [{color: []}],
                                                            ['link'],
                                                            ['clean'],
                                                        ],
                                                    },
                                                    clipboard: {
                                                        matchVisual: false,
                                                    },
                                                }}
                                                formats={[
                                                    'header',
                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                    'list', 'bullet', 'indent',
                                                    'link',
                                                    'color',
                                                    'formats/style', 'style', 'line', 'align', 'clean', 'hr'
                                                ]}
                                                onChange={(value) => {
                                                    this.setState({description: value});
                                                }}/>
                                    <hr/>
                                    <h5>Lista servizi</h5>
                                    {this.listServiceTags()}
                                    {this.renderExtraData()}
                                    {this.renderModalCommission()}
                                    <div className="text-center mt-4">
                                        <Button type="submit" color="success">Aggiungi ticket</Button>
                                    </div>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Container>
                    {this.renderModalExtraData()}
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(connect(mapStatetoProps, {})(TicketAddPage));
